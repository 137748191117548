import '@skyscanner/backpack-web/bpk-stylesheets';
import 'react-app-polyfill/ie11'; // For IE 11 support

import { createElement } from 'react';

import { createRoot } from 'react-dom/client';

import App from './App';

const root = createRoot(document.getElementById('root'));
root.render(createElement(App));
